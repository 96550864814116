import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';

import {Subject} from 'rxjs';

import {LoginService} from '../../../../pages/login/login.service';
import {AppService, UserService} from '../../../../shared/service/user.service';

import {ConfirmService} from '../../../../shared/components/confirm/confirm.service';

interface RunValue {
  idValue: string
  actionType: string
  taxCode?: string
  requireLoginKey?: boolean
}

@Injectable({
  providedIn: `root`
})
export class SigningService {

  readonly errorSubject: Subject<any>;

  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    private confirmService: ConfirmService,
    private loginService: LoginService
  ) {
    this.http = new HttpClient(this.backend);

    this.errorSubject = new Subject<any>();
  }

  async run(runValue: RunValue): Promise<void> {

    let loginKey = '';
    if (runValue.requireLoginKey !== false) {
      const apiKey: any = await new Promise(resolve => {
        this.loginService.getApiKey().subscribe(res => {
          resolve(res);
        });
      });
      loginKey = apiKey.token;
    }

    return new Promise((resolve, reject) => {
      const data = {
        baseUrl: 'https://hoadon.3asoft.vn/erp',
        loginKey,
        csrfToken: AppService.get('MOQUI_SESSION_TOKEN'),
        actionType: runValue.actionType,
        idValue: runValue.idValue,
        taxCode: runValue.taxCode || UserService.getUser().partyTaxId
      };

      this.http.post('http://localhost:13399/signing', data).subscribe({
        next: (response: any) => {

          if (response?.success) {
            resolve();
          } else if (response?.errorMessage) {
            this.confirmService.setConfirmDialog({
              message: `[${response?.errorCode}] ${response?.errorMessage}`,
              header: 'Ký điện tử không thành công',
              icon: 'pi pi-exclamation-triangle',
              rejectLabel: 'Đóng',
              acceptVisible: false
            });
            reject();
          } else {
            reject();
          }
        }, error: error => {
          this.errorSubject.next(error);
          reject();
        }
      })
    });
  }

}
