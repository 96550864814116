import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';

import {Subscription} from 'rxjs';

import {EntitiesComponent} from '../../../entities.component';

import {LoginService} from '../../../../pages/login/login.service';
import {ItemsService as OrganizationService} from '../../../customer/system/organization-info';
import {UserService} from '../../../../shared/service/user.service';
import {AuthService} from '../../../../shared/service/auth.service';
import {AppSubject} from '../../../../shared/app-subject';
import {Severity, ToastService} from '../../../../shared/components/toast/toast.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})
export class TopbarComponent implements OnInit, OnDestroy {

  AuthService = AuthService;

  user: any;
  displayName?: string;

  decreeAndCircularUsing?: string;

  changingPassword?: boolean;
  submittedPassword?: boolean;
  changePasswordForm = this.fb.group({
    oldPassword: ['', Validators.required],
    newPassword: ['', Validators.required],
    newPasswordVerify: ['', Validators.required]
  });
  changePasswordError?: string;

  decreeCircularSubscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public entities: EntitiesComponent,
    private loginService: LoginService,
    private organizationService: OrganizationService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.user = UserService.getUser();
    this.decreeCircularSubscription = AppSubject.getDecreeCircular().subscribe((decreeCircular) => {
      this.decreeAndCircularUsing = decreeCircular;
    });
    if (AuthService.hasRoles(['Customer'])) {
      this.loadDecreeCircular();

      this.displayName = this.user.partyTaxId;
    } else {
      this.displayName = this.user.partyName;
    }
  }

  ngOnDestroy(): void {
    if (this.decreeCircularSubscription) {
      this.decreeCircularSubscription.unsubscribe();
    }
  }

  loadDecreeCircular(): void {
    this.organizationService.get().subscribe((response) => {
      if (response && response.decreeAndCircularUsing) {
        AppSubject.setDecreeCircular(response.decreeAndCircularUsing);
      }
    });
  }

  toggleDecreeCircular(): void {
    this.organizationService.toggleDecreeCircular().subscribe(() => {
      this.loadDecreeCircular();
    });
  }

  changePassword(): void {
    this.changePasswordForm.reset();
    this.changingPassword = true;
  }

  onChangePassword(): void {
    if (!this.submittedPassword && this.changePasswordForm.valid) {
      this.submittedPassword = true;
      this.changePasswordError = '';

      this.loginService.changePassword(this.changePasswordForm.value).subscribe(({isSuccess, messages}) => {
        this.submittedPassword = false;
        if (isSuccess === 'Y') {
          this.toastService.setMessage({severity: Severity.SUCCESS, detail: messages});
          this.changingPassword = false;
        } else {
          this.changePasswordError = messages;
        }
      }, () => {
        this.submittedPassword = false;
      });
    }
  }

  logout(): void {
    this.loginService.logout().subscribe(() => {
      this.router.navigateByUrl('/login').then();
    });
  }

}
