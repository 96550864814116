import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {ProgressService} from '../components/progress/progress.service';
import {AppService} from '../service/user.service';
import {Severity, ToastService} from '../components/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private progressService: ProgressService,
    private toastService: ToastService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('progress') !== 'none') {
      this.progressService.setProgress(true);
    }

    const headers: any = {};
    if (['POST', 'PUT', 'DELETE'].includes(req.method.toUpperCase())) {
      headers['X-CSRF-Token'] = AppService.get(AppService.KEY.MOQUI_SESSION_TOKEN);
    }

    const request = req.clone({
      url: req.url.includes('://') ? req.url : `${environment.baseURL}/${req.url}`,
      setHeaders: headers
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type) {
          this.progressService.setProgress(false);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.progressService.setProgress(false);
        this.errorHandle(error);
        return throwError(error);
      })
    );
  }

  errorHandle(error: HttpErrorResponse) {
    const statusCode = error.status;
    if (statusCode === 400) {
      let detail = '';
      if (error.error && error.error.errors) {
        detail = error.error.errors.toString();
      }
      this.toastService.setMessage({severity: Severity.ERROR, detail});
    } else if (statusCode === 401) {
      this.toastService.setMessage({severity: Severity.ERROR, detail: 'Unauthorized'});
      this.router.navigateByUrl('/login').then();
    } else if (statusCode === 403) {
      this.toastService.setMessage({severity: Severity.ERROR, detail: 'Forbidden'});
    } else if (statusCode === 404) {
      this.toastService.setMessage({severity: Severity.ERROR, detail: 'NotFound'});
    } else {
      this.toastService.error();
    }
  }
}
