<div class="iam-topbar">
  <div class="iam-topbar-left">
    <div class="p-pt-1 p-pl-3">
      <a routerLink="/" title="3A INVOICE">
        <img src="assets/3a-soft/logo.jpeg" alt="3A INVOICE" style="height: 4rem">
      </a>
    </div>
  </div>
  <div class="iam-topbar-right">
    <div class="iam-topbar-menu">

    </div>
    <ul class="iam-topbar-actions">
      <li class="iam-topbar-action">
        <p-button label="{{ decreeAndCircularUsing === 'DecCir32' ? 'Nâng cấp lên TT78 - NĐ 123/2020/NĐ-CP' : 'Chuyển về TT32 - NĐ51' }}"
                  styleClass="p-button-outlined p-button-help p-button-rounded" (onClick)="toggleDecreeCircular()"
                  *ngIf="AuthService.hasRoles(['Customer']) && decreeAndCircularUsing === 'DecCir32'"></p-button>
        <a pRipple class="rounded-circle" (click)="entities.onTopbarActionClick($event, 'QuickCreate')" title="{{ 'QuickCreate'|translate }}"
           *ngIf="AuthService.hasRoles(['Customer'])">
          <i class="pi pi-plus-circle pi-large"></i>
        </a>
        <div *ngIf="entities.activeTopbarAction === 'QuickCreate'" class="iam-topbar-action-panel p-shadow-6 p-p-3">
          <div class="p-mb-3">
            <span style="font-size: 20px;">{{ 'QuickCreate'|translate }}</span>
          </div>
          <div class="p-d-flex">
            <div class="p-mr-5" style="width: 140px;">
              <div class="p-grid">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">{{ 'Invoice'|translate }}</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/customer/invoice/hdbh/create">Hóa đơn bán hàng</div>
              </div>
            </div>
            <div class="p-mr-5" style="width: 140px;">
              <div class="p-grid">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">{{ 'HandleInvoice'|translate }}</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/customer/invoice/cancelled">Hủy hóa đơn</div>
                <div class="p-col-12 pointer" routerLink="/customer/invoice/replaced">Thay thế hóa đơn</div>
                <div class="p-col-12 pointer" routerLink="/customer/invoice/adjusted">Điều chỉnh hóa đơn</div>
              </div>
            </div>
            <div class="p-mr-5" style="width: 230px;">
              <div class="p-grid">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">{{ 'RegisterToIssue'|translate }}</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/customer/register/invoice-templates/create">Mẫu hóa đơn</div>
                <!--                <div class="p-col-12">Quyết định áp dụng HĐĐT</div>-->
                <div class="p-col-12 pointer" routerLink="/customer/register/release-announcement/create">Thông báo phát hành HĐ</div>
                <!--                <div class="p-col-12">Thông báo hủy hóa đơn</div>-->
                <!--                <div class="p-col-12">Thông báo điều chỉnh thông tin HĐ</div>-->
              </div>
            </div>
            <div style="width: 140px;">
              <div class="p-grid">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">{{ 'Dictionary'|translate }}</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/customer/category/buyers/create">Khách hàng</div>
                <div class="p-col-12 pointer" routerLink="/customer/category/products/create">Hàng hóa, dịch vụ</div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="iam-topbar-action">
        <a pRipple class="rounded-circle" (click)="entities.onTopbarActionClick($event, 'Profile')" title="{{ 'Profile'|translate }}">
          <i class="pi pi-user pi-large"></i>
          <span class="p-ml-2" *ngIf="user">{{ displayName }}</span>
        </a>
        <ul *ngIf="entities.activeTopbarAction === 'Profile'" class="iam-topbar-action-panel p-shadow-6">
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" (click)="changePassword()">
              <i class="pi pi-unlock p-mr-2"></i>
              <span>{{ 'ChangePassword'|translate }}</span>
            </a>
          </li>
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" (click)="logout()">
              <i class="pi pi-power-off p-mr-2"></i>
              <span>{{ 'Logout'|translate }}</span>
            </a>
          </li>
          <li class="p-pt-2 p-pr-3 p-pl-3 p-text-center">
            <span style="font-size: 12px;color: #616161;">{{ user['partyName'] }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
  <p-dialog header="{{ 'ChangePassword'|translate }}" [(visible)]="changingPassword" [style]="{width: '500px'}" [resizable]="false" position="top">
    <p-message severity="info" text="Xin quý khách lưu ý, để đảm bảo an ninh tài khoản nên dùng một mật khẩu đầy đủ yếu tố sau: Sử dụng ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường và số."></p-message>
    <div class="p-fluid p-formgrid p-grid p-mt-2">
      <div class="p-field p-col-12">
        <label for="oldPassword">{{ 'Password'|translate }}<span class="required">*</span></label>
        <p-password id="oldPassword" formControlName="oldPassword" [toggleMask]="true" [feedback]="false"></p-password>
        <app-validator [control]="changePasswordForm.get('oldPassword')"></app-validator>
      </div>
      <div class="p-field p-col-12">
        <label for="newPassword">{{ 'NewPassword'|translate }}<span class="required">*</span></label>
        <p-password id="newPassword" formControlName="newPassword" [toggleMask]="true" [feedback]="false"></p-password>
        <app-validator [control]="changePasswordForm.get('newPassword')"></app-validator>
      </div>
      <div class="p-field p-col-12">
        <label for="newPasswordVerify">{{ 'NewPasswordVerify'|translate }}<span class="required">*</span></label>
        <p-password id="newPasswordVerify" formControlName="newPasswordVerify" [toggleMask]="true" [feedback]="false"></p-password>
        <app-validator [control]="changePasswordForm.get('newPasswordVerify')"></app-validator>
      </div>
    </div>
    <p-message severity="error" [text]="changePasswordError" *ngIf="changePasswordError"></p-message>
    <p-footer>
      <p-button label="{{ 'ChangePassword'|translate }}" icon="pi pi-check" styleClass="p-button-raised" type="submit" [disabled]="changePasswordForm.invalid"></p-button>
      <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="changingPassword=false"></p-button>
    </p-footer>
  </p-dialog>
</form>
