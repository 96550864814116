<div class="layout-wrapper" (click)="onLayoutClick()">
  <app-topbar></app-topbar>
  <div class="menu-wrapper">
    <app-menu></app-menu>
  </div>
  <div class="layout-main">
    <app-breadcrumb></app-breadcrumb>
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-signing></app-signing>
</div>
