import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import localeVi from '@angular/common/locales/vi';
registerLocaleData(localeVi);

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';

import {EntitiesComponent} from './entities/entities.component';
import {
  BreadcrumbComponent,
  MenuComponent,
  TopbarComponent
} from './entities/components/layout';

import {Interceptor} from './shared/interceptor';

import {SharedModule} from './shared/shared.module';
import {BreadcrumbModule} from 'primeng-lts/breadcrumb';
import {MegaMenuModule} from 'primeng-lts/megamenu';
import {EntitiesModule} from './entities/entities.module';

@NgModule({
  declarations: [
    AppComponent,
    EntitiesComponent,
    BreadcrumbComponent,
    MenuComponent,
    TopbarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    BreadcrumbModule,
    MegaMenuModule,
    EntitiesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'vi'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
