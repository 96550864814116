const languages: any = {
  Oops: {
    en: 'Oops...',
    vi: 'Có lỗi xảy ra...'
  },
  ServiceUnavailable: {
    en: 'Service Unavailable',
    vi: 'Lỗi kết nối, vui lòng thử lại sau'
  },
  Unauthorized: {
    en: '401 Unauthorized',
    vi: 'Phiên đăng nhập đã hết hạn'
  },
  Forbidden: {
    en: '403 Forbidden',
    vi: 'Bạn không có quyền truy cập'
  },
  NotFound: {
    en: '404 Not Found',
    vi: 'Dữ liệu không tồn tại'
  },
  SignIn: {
    en: 'Sign in',
    vi: 'Đăng nhập'
  },
  Logout: {
    en: 'Logout',
    vi: 'Đăng xuất'
  },
  ForgotPassword: {
    en: 'Forgot password?',
    vi: 'Quên mật khẩu?'
  },
  NeedHelp: {
    en: 'Need help?',
    vi: 'Cần hỗ trợ?'
  },
  Retry: {
    en: 'Retry',
    vi: 'Thử lại'
  },
  IncorrectPasswordError: {
    en: 'Password or username is incorrect',
    vi: 'Tên đăng nhập hoặc mật khẩu không chính xác!'
  },
  IncorrectUsernameError: {
    en: 'Password or username is incorrect',
    vi: 'Tên đăng nhập hoặc mật khẩu không chính xác!'
  },
  AttemptTooSoonError: {
    en: 'Account is currently locked. Try again later',
    vi: 'Tài khoản hiện đang bị khóa. Thử lại sau!'
  },
  AccountDisabled: {
    en: 'Account Disabled',
    vi: 'Tài khoản bị khóa!'
  },
  Disabled: {
    en: 'Disabled',
    vi: 'Khóa'
  },
  DataSavedSuccessfully: {
    en: 'Data saved successfully!',
    vi: 'Dữ liệu đã được cập nhật!'
  },
  Success: {
    en: 'Success',
    vi: 'Thành công!'
  },
  Home: {
    en: 'Home',
    vi: 'Trang chủ'
  },
  Profile: {
    en: 'Profile',
    vi: 'Thông tin cá nhân'
  },
  Dashboard: {
    en: 'Dashboard',
    vi: 'Tổng quan'
  },
  Customer: {
    en: 'Customer',
    vi: 'Khách hàng'
  },
  CustomerType: {
    en: 'Customer type',
    vi: 'Loại khách hàng'
  },
  ListOfCustomer: {
    en: 'List of customer',
    vi: 'Danh sách khách hàng'
  },
  CustomerPseudoId: {
    en: 'Customer Id',
    vi: 'Mã khách hàng'
  },
  CustomerName: {
    en: 'Customer name',
    vi: 'Tên khách hàng'
  },
  Report: {
    en: 'Report',
    vi: 'Báo cáo'
  },
  Back: {
    en: 'Back',
    vi: 'Quay lại'
  },
  Detail: {
    en: 'Detail',
    vi: 'Chi tiết'
  },
  Add: {
    en: 'Add',
    vi: 'Thêm'
  },
  Save: {
    en: 'Save',
    vi: 'Lưu'
  },
  Close: {
    en: 'Close',
    vi: 'Đóng'
  },
  Create: {
    en: 'Create',
    vi: 'Tạo mới'
  },
  Edit: {
    en: 'Edit',
    vi: 'Chỉnh sửa'
  },
  Update: {
    en: 'Update',
    vi: 'Cập nhật'
  },
  Delete: {
    en: 'Delete',
    vi: 'Xóa'
  },
  Lock: {
    en: 'Lock',
    vi: 'Khóa'
  },
  Unlock: {
    en: 'Unlock',
    vi: 'Mở khóa'
  },
  ProductId: {
    en: 'Product Id',
    vi: 'Mã sản phẩm'
  },
  Product: {
    en: 'Product',
    vi: 'Sản phẩm'
  },
  ProductName: {
    en: 'Product Name',
    vi: 'Tên sản phẩm'
  },
  Description: {
    en: 'Description',
    vi: 'Mô tả'
  },
  Status: {
    en: 'Status',
    vi: 'Trạng thái'
  },
  FieldIsRequired: {
    en: 'Field is required.',
    vi: 'Trường không được để trống.'
  },
  EmailInvalid: {
    en: 'Email invalid.',
    vi: 'Email không hợp lệ.'
  },
  PhoneNumberInvalid: {
    en: 'Phone number invalid.',
    vi: 'Số điện thoại không hợp lệ.'
  },
  FormatInvalid: {
    en: 'Format invalid.',
    vi: 'Định dạng không hợp lệ.'
  },
  Search: {
    en: 'Search',
    vi: 'Tìm kiếm'
  },
  Quantity: {
    en: 'Quantity',
    vi: 'Số lượng'
  },
  Information: {
    en: 'Information',
    vi: 'Thông tin'
  },
  Source: {
    en: 'Source',
    vi: 'Nguồn'
  },
  PseudoId: {
    en: 'Pseudo Id',
    vi: 'Mã'
  },
  Geo: {
    en: 'Geo',
    vi: 'Địa phương'
  },
  Address: {
    en: 'Address',
    vi: 'Địa chỉ'
  },
  SequenceNum: {
    en: 'SequenceNum',
    vi: 'Số thứ tự'
  },
  Content: {
    en: 'Content',
    vi: 'Nội dung'
  },
  Associated: {
    en: 'Associated',
    vi: 'Liên kết'
  },
  Categories: {
    en: 'Categories',
    vi: 'Danh mục'
  },
  Identification: {
    en: 'Identification',
    vi: 'Mã vạch'
  },
  Features: {
    en: 'Features',
    vi: 'Đặc điểm'
  },
  Brand: {
    en: 'Brand',
    vi: 'Thương hiệu'
  },
  DateTime: {
    en: 'DateTime',
    vi: 'Thời gian'
  },
  Employee: {
    en: 'Employee',
    vi: 'Nhân viên'
  },
  Phone: {
    en: 'Phone',
    vi: 'Số điện thoại'
  },
  Email: {
    en: 'Email',
    vi: 'Email'
  },
  EmailType: {
    en: 'Email type',
    vi: 'Loại Email'
  },
  Subject: {
    en: 'Subject',
    vi: 'Tiêu đề'
  },
  ToAddresses: {
    en: 'To addresses',
    vi: 'Đến địa chỉ'
  },
  SentDate: {
    en: 'Sent date',
    vi: 'Ngày gửi'
  },
  FullName: {
    en: 'Full Name',
    vi: 'Họ và tên'
  },
  Name: {
    en: 'Name',
    vi: 'Tên'
  },
  BirthDate: {
    en: 'BirthDate',
    vi: 'Ngày sinh'
  },
  Gender: {
    en: 'Gender',
    vi: 'Giới tính'
  },
  ShippingAddress: {
    en: 'Shipping Address',
    vi: 'Địa chỉ giao hàng'
  },
  Select: {
    en: 'Select',
    vi: 'Chọn'
  },
  Date: {
    en: 'Date',
    vi: 'Ngày'
  },
  CreatedDate: {
    en: 'Created Date',
    vi: 'Ngày tạo'
  },
  FromDate: {
    en: 'From Date',
    vi: 'Từ ngày'
  },
  ThruDate: {
    en: 'Thru Date',
    vi: 'Đến ngày'
  },
  Print: {
    en: 'Print',
    vi: 'In'
  },
  Deadline: {
    en: 'Deadline',
    vi: 'Hạn chót'
  },
  Organization: {
    en: 'Organization',
    vi: 'Tổ chức'
  },
  Synchronization: {
    en: 'Synchronization',
    vi: 'Đồng bộ'
  },
  Username: {
    en: 'Username',
    vi: 'Tên đăng nhập'
  },
  Password: {
    en: 'Password',
    vi: 'Mật khẩu'
  },
  ChangePassword: {
    en: 'Change Password',
    vi: 'Đổi mật khẩu'
  },
  NewPassword: {
    en: 'New Password',
    vi: 'Mật khẩu mới'
  },
  NewPasswordVerify: {
    en: 'New Password Verify',
    vi: 'Nhập lại mật khẩu mới'
  },
  All: {
    en: 'All',
    vi: 'Tất cả'
  },
  InPeriod: {
    en: 'In Period',
    vi: 'Trong khoảng thời gian'
  },
  AllTime: {
    en: 'All time',
    vi: 'Toàn thời gian'
  },
  Last30days: {
    en: 'Last 30 days',
    vi: '30 ngày qua'
  },
  Last7days: {
    en: 'Last 7 days',
    vi: '7 ngày qua'
  },
  Today: {
    en: 'Today',
    vi: 'Hôm nay'
  },
  Schedule: {
    en: 'Schedule',
    vi: 'Lịch trình'
  },
  On: {
    en: 'On',
    vi: 'Bật'
  },
  Off: {
    en: 'Off',
    vi: 'Tắt'
  },
  Group: {
    en: 'Group',
    vi: 'Nhóm'
  },
  Payment: {
    en: 'Payment',
    vi: 'Thanh toán'
  },
  Comments: {
    en: 'Comments',
    vi: 'Ghi chú'
  },
  Total: {
    en: 'Total',
    vi: 'Tổng cộng'
  },
  QuickCreate: {
    en: 'Quick create',
    vi: 'Thêm nhanh'
  },
  Notifications: {
    en: 'Notifications',
    vi: 'Thông báo'
  },
  Invoice: {
    en: 'Invoice',
    vi: 'Hóa đơn '
  },
  HandleInvoice: {
    en: 'Handle invoice',
    vi: 'Xử lý hóa đơn'
  },
  RegisterToIssue: {
    en: 'Register to issue',
    vi: 'Đăng ký phát hành'
  },
  Dictionary: {
    en: 'Dictionary',
    vi: 'Danh mục'
  },
  System: {
    en: 'System',
    vi: 'Hệ thống'
  },
  AccessLogs: {
    en: 'Access logs',
    vi: 'Nhật ký truy cập'
  },
  ConnectionInstruction: {
    en: 'Connection instruction',
    vi: 'Hướng dẫn kết nối'
  },
  ElectronicInvoiceRegulations: {
    en: 'Electronic invoice regulations',
    vi: 'Quy định HĐĐT'
  },
  SaleInvoice: {
    en: 'Sale invoice',
    vi: 'Hóa đơn bán hàng'
  },
  CancelInvoice: {
    en: 'Cancel invoice',
    vi: 'Hủy hóa đơn'
  },
  CancelledInvoice: {
    en: 'Cancelled invoice',
    vi: 'Hóa đơn hủy bỏ'
  },
  CancelledDate: {
    en: 'Cancelled date',
    vi: 'Ngày hủy'
  },
  ReplaceInvoice: {
    en: 'Replace invoice',
    vi: 'Thay thế hóa đơn'
  },
  ReplacedInvoice: {
    en: 'Replaced invoice',
    vi: 'Hóa đơn thay thế'
  },
  Replace: {
    en: 'Replace',
    vi: 'Thay thế'
  },
  AdjustInvoice: {
    en: 'Adjust invoice',
    vi: 'Điều chỉnh hóa đơn'
  },
  Adjust: {
    en: 'Adjust',
    vi: 'Điều chỉnh'
  },
  InvoiceTemplate: {
    en: 'Invoice template',
    vi: 'Mẫu hóa đơn'
  },
  InvoiceTemplateName: {
    en: "Invoice template's name",
    vi: 'Tên mẫu hóa đơn'
  },
  InvoiceTemplateType: {
    en: "Invoice template type",
    vi: 'Loại mẫu hoá đơn'
  },
  InvoiceType: {
    en: "Invoice type",
    vi: 'Loại hoá đơn'
  },
  PrintInvoiceT: {
    en: "Print invoice",
    vi: 'In hoá đơn'
  },
  ReleaseDecision: {
    en: 'Release decision',
    vi: 'Quyết định áp dụng HĐĐT'
  },
  ReleaseAnnouncement: {
    en: 'Release announcement',
    vi: 'Thông báo phát hành'
  },
  ErrorsAnnouncement: {
    en: 'Errors announcement',
    vi: 'Thông báo HĐ sai sót'
  },
  ListOfUsedInvoices: {
    en: 'List of used invoices',
    vi: 'Bảng kê'
  },
  DetailedListOfUsedInvoices: {
    en: 'Detailed list of used invoices',
    vi: 'Bảng kê chi tiết'
  },
  SummaryOfQuantityOfIssuedInvoices: {
    en: 'Summary of quantity of issued invoices',
    vi: 'Số lượng hóa đơn đã phát hành'
  },
  ReportOnInvoiceUsingSituation: {
    en: 'Report on invoice using situation',
    vi: 'Tình hình sử dụng hóa đơn'
  },
  GoodsServices: {
    en: 'Goods, services',
    vi: 'Hàng hóa, dịch vụ'
  },
  GoodsServiceCategory: {
    en: 'Goods, service category',
    vi: 'Nhóm hàng hóa, dịch vụ'
  },
  Currency: {
    en: 'Currency',
    vi: 'Loại tiền'
  },
  ExchangeRate: {
    en: 'Exchange rate',
    vi: 'Tỷ giá'
  },
  EmailTemplate: {
    en: 'Email template',
    vi: 'Mẫu email doanh nghiệp'
  },
  EmailHistoryLogs: {
    en: 'Email history logs',
    vi: 'Lịch sử gửi mail'
  },
  Agent: {
    en: 'Agent',
    vi: 'Đại lý'
  },
  CreateAgent: {
    en: 'CreateAgent',
    vi: 'Tạo mới đại lý'
  },
  ListOfAgents: {
    en: 'List of agent',
    vi: 'Danh sách đại lý'
  },
  AgentType: {
    en: 'Agent type',
    vi: 'Loại đại lý'
  },
  AgentPseudoId: {
    en: 'Agent PseudoId',
    vi: 'Mã đại lý'
  },
  AgentName: {
    en: 'Agent Name',
    vi: 'Tên công ty'
  },
  TaxCode: {
    en: 'Tax code',
    vi: 'Mã số thuế'
  },
  TaxAmount: {
    en: 'Tax amount',
    vi: 'Tiền thuế'
  },
  Representative: {
    en: 'Representative',
    vi: 'Người đại diện'
  },
  RepresentativeName: {
    en: 'Representative name',
    vi: 'Tên người đại diện'
  },
  RepresentativeJobTitle: {
    en: 'Representative job title',
    vi: 'Chức vụ người đại diện'
  },
  ContactPerson: {
    en: 'Contact person',
    vi: 'Người liên hệ'
  },
  ContactPersonName: {
    en: 'Contact person name',
    vi: 'Tên người liên hệ'
  },
  ContactPersonJobTitle: {
    en: 'Contact person job title',
    vi: 'Chức vụ người liên hệ'
  },
  M: {
    en: 'Male',
    vi: 'Nam'
  },
  F: {
    en: 'Female',
    vi: 'Nữ'
  },
  AgentParent: {
    en: 'Parent',
    vi: 'Đại lý quản lý'
  },
  Request: {
    en: 'Request',
    vi: 'Yêu cầu'
  },
  RequestCreateCustomer: {
    en: 'Request create customer',
    vi: 'Yêu cầu tạo khách hàng'
  },
  RequestType: {
    en: 'Request type',
    vi: 'Loại yêu cầu'
  },
  Agreement: {
    en: 'Agreement',
    vi: 'Hợp đồng'
  },
  ListOfAgreement: {
    en: 'List of agreement',
    vi: 'Danh sách hợp đồng'
  },
  AgreementId: {
    en: 'Agreement Id',
    vi: 'Mã hợp đồng'
  },
  AgreementType: {
    en: 'Agreement type',
    vi: 'Loại hợp đồng'
  },
  AgreementNo: {
    en: 'Agreement No',
    vi: 'Số hợp đồng'
  },
  AgreementDate: {
    en: 'Agreement date',
    vi: 'Ngày tạo hợp đồng'
  },
  View: {
    en: 'View',
    vi: 'Xem'
  },
  QuickView: {
    en: 'Quick view',
    vi: 'Xem nhanh'
  },
  ViewDetail: {
    en: 'View detail',
    vi: 'Xem chi tiết'
  },
  Company: {
    en: 'Company',
    vi: 'Đơn vị'
  },
  BankName: {
    en: 'Bank name',
    vi: 'Ngân hàng'
  },
  BankAccount: {
    en: 'Bank account',
    vi: 'Số tài khoản'
  },
  Province: {
    en: 'Province',
    vi: 'Tỉnh/thành phố'
  },
  County: {
    en: 'County',
    vi: 'Quận/huyện'
  },
  TaxAuthority: {
    en: 'Tax Authority',
    vi: 'Cơ quan thuế'
  },
  WebAddress: {
    en: 'Web address',
    vi: 'Địa chỉ website'
  },
  FaxNumber: {
    en: 'Fax Number',
    vi: 'Số Fax'
  },
  Address1: {
    en: 'Address',
    vi: 'Số nhà, phường/xã'
  },
  ServicePack: {
    en: 'Service Pack',
    vi: 'Gói dịch vụ'
  },
  ServiceType: {
    en: 'Service type',
    vi: 'Loại dịch vụ'
  },
  TF_mon: {
    en: 'Month',
    vi: 'Tháng'
  },
  TF_yr: {
    en: 'Year',
    vi: 'Năm'
  },
  AmountUom: {
    en: 'Amount uom',
    vi: 'Đơn vị'
  },
  Price: {
    en: 'Price',
    vi: 'Giá'
  },
  UnitPrice: {
    en: 'Unit price',
    vi: 'Đơn giá'
  },
  BUYER: {
    en: 'BUYER',
    vi: 'Bên mua'
  },
  BuyerConfirmDate: {
    en: 'Buyer Confirm Date',
    vi: 'Ngày ký bên mua'
  },
  SELLER: {
    en: 'SELLER',
    vi: 'Bên bán'
  },
  SellerConfirmDate: {
    en: 'Seller Confirm Date',
    vi: 'Ngày ký bên bán'
  },
  OrganizationInfo: {
    en: 'Organization info',
    vi: 'Thông tin doanh nghiệp'
  },
  OrganizationName: {
    en: 'Organization name',
    vi: 'Tên doanh nghiệp'
  },
  Resend: {
    en: 'Resend',
    vi: 'Gửi lại'
  },
  Send: {
    en: 'Send',
    vi: 'Gửi'
  },
  InvoiceForm: {
    en: 'Invoice Form',
    vi: 'Mẫu số'
  },
  InvoiceSerial: {
    en: 'Invoice Serial',
    vi: 'Ký hiệu'
  },
  InvoiceNo: {
    en: 'No.',
    vi: 'Số'
  },
  InvoiceDate: {
    en: 'Invoice date',
    vi: 'Ngày HĐ'
  },
  InvoiceId: {
    en: 'Invoice id',
    vi: 'Mã HĐ'
  },
  Y: {
    en: 'Yes',
    vi: 'Có'
  },
  N: {
    en: 'No',
    vi: 'Không'
  },
  PaymentMethod: {
    en: 'Payment method',
    vi: 'Hình thức TT'
  },
  Discount: {
    en: 'Discount',
    vi: 'Chiết khấu'
  },
  DiscountPercent: {
    en: 'Discount percent',
    vi: 'Tỷ lệ CK (%)'
  },
  TaxRate: {
    en: 'Tax rate',
    vi: 'Thuế GTGT'
  },
  Buyers: {
    en: 'Buyers',
    vi: 'Khách hàng'
  },
  Buyer: {
    en: 'Buyer',
    vi: 'Người mua hàng'
  },
  BuyerCode: {
    en: "Buyer's code",
    vi: 'Mã khách hàng'
  },
  BuyerTaxCode: {
    en: "Buyer's tax code",
    vi: 'MST người mua'
  },
  CompanyName: {
    en: "Company's name",
    vi: 'Tên đơn vị'
  },
  GetInfo: {
    en: "Get info",
    vi: 'Lấy thông tin'
  },
  Seller: {
    en: "Seller",
    vi: 'Đơn vị bán hàng'
  },
  VatInvoice: {
    en: "Vat Invoice",
    vi: 'Hoá đơn giá trị gia tăng'
  },
  Paid: {
    en: "Paid",
    vi: 'Đã thanh toán'
  },
  Unpaid: {
    en: "Unpaid",
    vi: 'Chưa thanh toán'
  },
  TextColor: {
    en: "Text color",
    vi: 'Màu chữ'
  },
  Font: {
    en: "Font",
    vi: 'Font chữ'
  },
  UseMultipleLanguage: {
    en: "Use multiple language",
    vi: 'Sử dụng đa ngôn ngữ'
  },
  UseLogo: {
    en: "Use logo",
    vi: 'Sử dụng logo'
  },
  UseBackground: {
    en: "Use background",
    vi: 'Sử dụng hình nền'
  },
  UseSurround: {
    en: "Use surround",
    vi: 'Sử dụng khung viền'
  },
  Size: {
    en: "Size",
    vi: 'Kích thước'
  },
  Width: {
    en: "Width",
    vi: 'Rộng'
  },
  Height: {
    en: "Height",
    vi: 'Cao'
  },
  BrowseFromComputer: {
    en: "Browse from computer",
    vi: 'Chọn từ máy tính'
  },
  BrowseFromGallery: {
    en: "Browse from gallery",
    vi: 'Chọn ảnh nền từ thư viện'
  },
  MarginTop: {
    en: "Margin top",
    vi: 'Căn top'
  },
  MarginLeft: {
    en: "Margin left",
    vi: 'Căn trái'
  },
  Opacity: {
    en: "Opacity",
    vi: 'Độ mờ'
  },
  Title: {
    en: "Title",
    vi: 'Tiêu đề'
  },
  EnglishTitle: {
    en: "English title",
    vi: 'Tiêu đề tiếng Anh'
  },
  FieldType: {
    en: "Field type",
    vi: 'Kiểu trường'
  },
  DataType: {
    en: "Data type",
    vi: 'Kiểu dữ liệu'
  },
  DefaultValue: {
    en: "Default value",
    vi: 'Giá trị mặc định'
  },
  Type: {
    en: "Type",
    vi: 'Loại'
  },
  AddBlankRow: {
    en: "Add blank row",
    vi: 'Thêm dòng trống'
  },
  ReferenceNumber: {
    en: "Reference number",
    vi: 'Mã tra cứu'
  },
  InvoiceTotal: {
    en: "Invoice total",
    vi: 'Tổng tiền'
  },
  Confirmation: {
    en: "Confirmation",
    vi: 'Xác nhận'
  },
  Reset: {
    en: "Reset",
    vi: 'Đặt lại mặc định'
  },
  Publish: {
    en: "Publish",
    vi: 'Phát hành'
  },
  PublishInvoice: {
    en: "Publish invoice",
    vi: 'Phát hành hóa đơn'
  },
  SentEmail: {
    en: "Sent email",
    vi: 'Gửi email'
  },
  Period: {
    en: "Period",
    vi: 'Kỳ'
  },
  Done: {
    en: "Done",
    vi: 'Xong'
  },
  Download: {
    en: "Download",
    vi: 'Tải xuống'
  },
  Agree: {
    en: "Agree",
    vi: 'Đồng ý'
  },
  CancelDate: {
    en: "Cancel date",
    vi: 'Ngày hủy'
  },
  CancelReason: {
    en: "Cancel reason",
    vi: 'Lý do hủy'
  },
  Attachment: {
    en: "Attachment",
    vi: 'Tệp đính kèm'
  },
  Minutes: {
    en: "Minutes",
    vi: 'Biên bản'
  },
  Decision: {
    en: "Decision",
    vi: 'Quyết định'
  },
  Approve: {
    en: "Approve",
    vi: 'Duyệt'
  },
  Reject: {
    en: "Reject",
    vi: 'Từ chối'
  },
  Renew: {
    en: "Renew",
    vi: 'Gia hạn'
  },
  Actions: {
    en: "Actions",
    vi: 'Hành động'
  },
  VIEW: {
    en: 'View',
    vi: 'Xem'
  },
  CREATE: {
    en: 'Create',
    vi: 'Tạo'
  },
  DELETE: {
    en: 'Delete',
    vi: 'Xoá'
  },
  UPDATE: {
    en: 'Update',
    vi: 'Sửa'
  },
  'DIGITAL-SIGNING': {
    en: 'Digital Signing',
    vi: 'Ký xác thực'
  },
};

export default languages
