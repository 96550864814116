import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import {SigningService} from './signing.service';

@Component({
  selector: 'app-signing',
  templateUrl: './signing.component.html'
})
export class SigningComponent implements OnInit, OnDestroy {

  errorSubscription?: Subscription;

  pluginNotFound?: boolean;

  constructor(
    private certificatedService: SigningService
  ) {
  }

  ngOnInit(): void {
    this.errorSubscription = this.certificatedService.errorSubject.subscribe(error => {
      if (error) {
        if (error.status === 0) {
          this.pluginNotFound = true;
        }
        console.log(error);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

}
