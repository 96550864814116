export class AppService {

  static readonly app = 'iam-viet-invoice-ng.v1.0.0';

  static readonly KEY = {
    SIGN: 'myinvoice',
    MOQUI_SESSION_TOKEN: 'MOQUI_SESSION_TOKEN'
  };

  static set(key: string, value: string): void {
    localStorage.setItem(`${this.app}_${key}`, value);
  }

  static get(key: string): string {
    return localStorage.getItem(`${this.app}_${key}`) || '';
  }

  static remove(key: string): void {
    localStorage.removeItem(`${this.app}_${key}`);
  }

  static removeAll(): void {
    this.remove(this.KEY.MOQUI_SESSION_TOKEN);
  }

}

export class UserService {

  static readonly app = AppService.app;

  static readonly KEY = {
    SETTING: 'SETTING',
    ROUTER_STATE: 'ROUTER_STATE',
    PRIVILEGE: 'PRIVILEGE'
  };

  static setUser(user: any): void {
    AppService.set(`${this.app}_user`, JSON.stringify(user));
  }

  static getUser(): any {
    return JSON.parse(AppService.get(`${this.app}_user`) || '{}');
  }

  static set(key: string, value: string): void {
    AppService.set(`${this.app}_${key}_${this.getUser().partyId}`, value);
  }

  static get(key: string): string {
    return AppService.get(`${this.app}_${key}_${this.getUser().partyId}`);
  }

  static remove(key: string): void {
    AppService.remove(`${this.app}_${key}_${this.getUser().partyId}`);
  }

  static removeAll(): void {
    this.remove(this.KEY.PRIVILEGE);
  }

}
