import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {
  IamTableComponent,
  IamPeriodComponent,
  CountyGeoComponent,
  TaxAuthorityComponent,
  AttachmentViewerComponent,
  SigningComponent
} from './components/entity';

@NgModule({
  declarations: [
    IamTableComponent,
    IamPeriodComponent,
    CountyGeoComponent,
    TaxAuthorityComponent,
    AttachmentViewerComponent,
    SigningComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    IamTableComponent,
    IamPeriodComponent,
    CountyGeoComponent,
    TaxAuthorityComponent,
    AttachmentViewerComponent,
    SigningComponent
  ]
})
export class EntitiesModule {
}
