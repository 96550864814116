import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private readonly url = 'iam-auth';

  constructor(private http: HttpClient) {
  }

  login(item: any): Observable<any> {
    return this.http.post(`${this.url}/login`, item);
  }

  changePassword(item: any): Observable<any> {
    return this.http.post(`${this.url}/change-password`, item);
  }

  resetPassword(item: any): Observable<any> {
    return this.http.put(`${this.url}/reset-password`, item);
  }

  logout(): Observable<any> {
    return this.http.post(`${this.url}/logout`, {});
  }

  getToken(): Observable<any> {
    return this.http.get(`${this.url}/token`);
  }

  getApiKey(): Observable<any> {
    return this.http.get(`${this.url}/api-key`);
  }

  getMe(): Observable<any> {
    return this.http.get(`iam-common/me`);
  }

  testCertificated(): Observable<any> {
    return this.http.post(`http://localhost:24038/certificated`, {});
  }

}
