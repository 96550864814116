import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import {MenuItem} from 'primeng-lts/api';

import {BreadcrumbService} from '../breadcrumb/breadcrumb.service';
import {AuthService} from '../../../../shared/service/auth.service';
import {TranslateService} from '../../../../shared/translate/translate.service';
import {AppSubject} from '../../../../shared/app-subject';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnDestroy {

  menus: Array<MenuItem> = new Array<MenuItem>();

  decreeCircularSubscription?: Subscription;
  decreeCircular?: string;

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.setBreadcrumb(event.urlAfterRedirects);
    });
  }

  ngOnInit(): void {
    if (AuthService.hasRoles(['Customer'])) {
      this.decreeCircularSubscription = AppSubject.getDecreeCircular().subscribe((decreeCircular) => {
        this.decreeCircular = decreeCircular;
        this.initMenu();
      });
    } else {
      this.initMenu();
    }
  }

  initMenu(): void {
    if (AuthService.hasRoles(['Customer'])) {
      this.menus = this.getCustomerMenus();
    } else {
      this.menus = this.getManagerMenus();
    }
    this.menus.forEach((menu) => {
      if (menu.items) {
        let visible = false;
        menu.items.forEach((item) => {
          if (item.visible !== false) {
            visible = true;
          }
        });
        if (menu.visible === undefined) {
          menu.visible = visible;
        }
      }
    });
    this.setBreadcrumb();
  }

  ngOnDestroy(): void {
    if (this.decreeCircularSubscription) {
      this.decreeCircularSubscription.unsubscribe();
    }
  }

  getCustomerMenus(): Array<MenuItem> {
    return [
      {
        label: this.translate.get('Dashboard'),
        icon: 'pi pi-fw pi-desktop',
        routerLink: '/dashboard'
      },
      {
        label: 'Quản lý hoá đơn',
        icon: 'pi pi-fw pi-book',
        items: [
          {label: 'Hóa đơn bán hàng', routerLink: '/customer/invoice/hdbh', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SalesInvoice', 'SalesInvoice1', 'VIEW')},
          {label: 'Phiếu xuất kho', routerLink: '/customer/invoice/pxk', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SalesInvoice', 'SalesInvoice1', 'VIEW')}
        ],
        visible: AuthService.hasPrivilege('SalesInvoice', 'SalesInvoice1', 'VIEW')
      },
      {
        label: this.translate.get('HandleInvoice'),
        icon: 'pi pi-fw pi-check-square',
        styleClass: 'iam-menu-22',
        items: [
          {label: this.translate.get('CancelInvoice'), routerLink: '/customer/invoice/cancelled', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SalesInvoiceCancel', 'SalesInvoiceCancel1', 'VIEW')},
          {label: this.translate.get('ReplaceInvoice'), routerLink: '/customer/invoice/replaced', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SalesInvoiceReplace', 'SalesInvoiceReplace1', 'VIEW')},
          {label: this.translate.get('AdjustInvoice'), routerLink: '/customer/invoice/adjusted', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SalesInvoiceAdjustment', 'SalesInvoiceAdjustment1', 'VIEW')},
          {
            label: 'Thông báo HĐ sai sót 04/SS-HĐĐT', routerLink: '/customer/invoice/errors-announcement', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('ErrorAnnouncement', 'ErrorAnnouncement1', 'VIEW') && this.decreeCircular === 'DecCir78'
          }
        ]
      },
      {
        label: this.translate.get('RegisterToIssue'),
        icon: 'pi pi-fw pi-external-link',
        styleClass: 'iam-menu-16',
        items: [
          {
            label: this.translate.get('Đăng ký/Thay đổi thông tin'), routerLink: '/customer/register/using-register', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('UsingRegisterAnnouncement', 'UsingRegisterAnnouncement1', 'VIEW') && this.decreeCircular === 'DecCir78'
          },
          {label: this.translate.get('InvoiceTemplate'), routerLink: '/customer/register/invoice-templates', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceTemplateMgr', 'InvoiceTemplateMgr1', 'VIEW')},
          {
            label: this.translate.get('ReleaseDecision'), routerLink: '/customer/register/release-decision', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('ReleaseDecision', 'ReleaseDecision1', 'VIEW') && this.decreeCircular === 'DecCir32'
          },
          {
            label: this.translate.get('ReleaseAnnouncement'), routerLink: '/customer/register/release-announcement', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('ReleaseAnnouncement', 'ReleaseAnnouncement1', 'VIEW') && this.decreeCircular === 'DecCir32'
          }
        ]
      },
      {
        label: this.translate.get('Lịch sử truyền nhận'),
        icon: 'pi pi-fw pi-sort-alt',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('ĐK/Thay đổi thông tin'), routerLink: '/customer/tvan/agrt-using-register', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('AgrtUsingRegisterTvan', 'AgrtUsingRegisterTvan1', 'VIEW')},
          {label: this.translate.get('Hóa đơn điện tử'), routerLink: '/customer/tvan/invoices', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceTvan', 'InvoiceTvan1', 'VIEW')},
          {label: this.translate.get('Xử lý hóa đơn'), routerLink: '/customer/tvan/agrt-error-announce', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('AgrtErrorAnnounceTvan', 'AgrtErrorAnnounceTvan1', 'VIEW')}
        ],
        visible: this.decreeCircular === 'DecCir78'
      },
      {
        label: this.translate.get('Report'),
        icon: 'pi pi-fw pi-chart-line',
        styleClass: 'iam-menu-22',
        items: [
          {label: 'Bảng kê hóa đơn đã sử dụng', routerLink: '/customer/report/invoice-summary', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceSummaryReport', 'InvoiceSummaryReport1', 'VIEW')},
          {label: 'Bảng kê chi tiết hóa đơn đã sử dụng', routerLink: '/customer/report/invoice-item-detail', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceItemDetailReport', 'InvoiceItemDetailReport1', 'VIEW')},
          {label: 'Bảng kê hóa đơn tổng hợp', routerLink: '/customer/report/invoice-stat-by-tax', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceItemDetailReport', 'InvoiceItemDetailReport1', 'VIEW')},
          {label: this.translate.get('SummaryOfQuantityOfIssuedInvoices'), routerLink: '/customer/report/invoice-template-stat', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('IssuedInvoiceCountReport', 'IssuedInvoiceCountReport1', 'VIEW')},
          {
            label: 'Báo cáo tình hình sử dụng hóa đơn', routerLink: '/customer/report/invoice-using-report', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('InvoiceUsingReportReport', 'InvoiceUsingReportReport1', 'VIEW') && this.decreeCircular === 'DecCir32'
          },
          {
            label: 'Bảng tổng hợp dữ liệu HĐĐT', routerLink: '/customer/report/invoice-stats-period', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('InvoiceStatsPeriod', 'InvoiceStatsPeriod1', 'VIEW') && this.decreeCircular === 'DecCir78'
          }
        ]
      },
      {
        label: this.translate.get('Dictionary'),
        icon: 'pi pi-fw pi-list',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('Buyers'), routerLink: '/customer/category/buyers', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('PartyBuyer', 'PartyBuyer1', 'VIEW')},
          {label: this.translate.get('GoodsServices'), routerLink: '/customer/category/products', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerProduct', 'SellerProduct1', 'VIEW')},
          {label: this.translate.get('Currency'), routerLink: '/customer/category/currencies', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerCurrencies', 'SellerCurrencies1', 'VIEW')}
        ]
      },
      {
        label: this.translate.get('System'),
        icon: 'pi pi-fw pi-sliders-h',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('OrganizationInfo'), routerLink: '/customer/system/organization-info', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('OrganizationInfo', 'OrganizationInfo1', 'VIEW')},
          {label: 'Quản lý người dùng', routerLink: '/customer/system/user-accounts', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerAdmin', 'SellerAdmin1', 'VIEW')},
          {label: this.translate.get('EmailHistoryLogs'), routerLink: '/common/email-messages', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerEmailLog', 'SellerEmailLog1', 'VIEW')},
          {label: 'Cấu hình hệ thống', routerLink: '/customer/system/options', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SystemConfig', 'SystemConfig1', 'VIEW')},
          {label: 'Thông tin nhà cung cấp', routerLink: '/common/provider-info', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('PartyProviderInfo', 'PartyProviderInfo1', 'VIEW')},
          {label: 'Hợp đồng đã mua', routerLink: '/customer/system/orders', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerAgreement', 'SellerAgreement1', 'VIEW')},
          // {label: 'Tải .NET Framework 4.7', url: '/assets/tools/NET-Framework-4.5.2.exe', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Tải phần mềm ký', url: 'https://drive.google.com/drive/folders/1r5qinsNgEDOJ7_6r0raJaT_GBHv87U3w?usp=sharing', icon: 'pi pi-fw pi-circle-off', target:'_blank'}
        ]
      }
    ];
  }

  getManagerMenus(): Array<MenuItem> {
    return [
      {
        label: this.translate.get('Dashboard'),
        icon: 'pi pi-fw pi-desktop',
        routerLink: '/dashboard'
      },
      {
        label: this.translate.get('Agent'),
        icon: 'pi pi-fw pi-sitemap',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('CreateAgent'), routerLink: '/manager/q-agents/create', icon: 'pi pi-fw pi-plus-circle'},
          {label: this.translate.get('ListOfAgents'), routerLink: '/manager/agents', icon: 'pi pi-fw pi-circle-off'},
        ]
      },
      {
        label: this.translate.get('Customer'),
        icon: 'pi pi-fw pi-users',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('Request'), routerLink: '/manager/customer/requests', icon: 'pi pi-fw pi-circle-off'},
          {label: this.translate.get('ListOfCustomer'), routerLink: '/manager/customer/customers', icon: 'pi pi-fw pi-circle-off'},
          {label: this.translate.get('ListOfAgreement'), routerLink: '/manager/customer/agreements', icon: 'pi pi-fw pi-circle-off'},
          {label: this.translate.get('Payment'), routerLink: '/manager/customer/payments', icon: 'pi pi-fw pi-circle-off'}
        ]
      },
      {
        label: this.translate.get('Dictionary'),
        icon: 'pi pi-fw pi-list',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('ServicePack'), routerLink: '/manager/category/products', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
          {label: 'Cơ quan thuế', routerLink: '/manager/category/tax-authorities', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
        ],
        visible: AuthService.hasRoles(['Administrator'])
      },
      {
        label: this.translate.get('System'),
        icon: 'pi pi-fw pi-sliders-h',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('EmailHistoryLogs'), routerLink: '/common/email-messages', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Thông tin nhà cung cấp', routerLink: '/common/provider-info', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Quản lý tài khoản', routerLink: '/manager/system/user-accounts', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
          {label: 'Hóa đơn chưa nhận phản hồi của CQT', routerLink: '/manager/system/invoice-gdt-processing', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
          {label: 'Thông báo chung', routerLink: '/manager/system/product-introduction', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])}
        ]
      },
      {
        label: this.translate.get('Report'),
        icon: 'pi pi-fw pi-chart-line',
        styleClass: 'iam-menu-22',
        items: [
          {label: 'Báo cáo tổng hợp', routerLink: '/manager/report/agreement-customer-summary', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Báo cáo chi tiết khách hàng', routerLink: '/manager/report/agreement-customer-detail', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Báo cáo đại lý', routerLink: '/manager/report/agreement-agents', icon: 'pi pi-fw pi-circle-off'}
        ]
      }
    ];
  }

  setBreadcrumb(url?: string): void {
    if (this.menus.length) {
      let breadcrumb: string = '';
      for (const menu of this.menus) {
        if (this.isActive(menu.routerLink) && menu.label) {
          breadcrumb = menu.label;
          break;
        } else if (this.isActive(`${menu.routerLink}/create`) && menu.label) {
          breadcrumb = `${menu.label}(-)${this.translate.get('Create')}`;
          break;
        } else if (this.isActive(`${menu.routerLink}/detail`, true) && menu.label) {
          breadcrumb = `${menu.label}(-)${this.translate.get('Detail')}`;
          break;
        } else if (this.isActive(`${menu.routerLink}/update`, true) && menu.label) {
          breadcrumb = `${menu.label}(-)${this.translate.get('Edit')}`;
          break;
        }
        if (menu.items) {
          for (const item of menu.items) {
            if (this.isActive(item.routerLink) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}`;
              break;
            } else if (this.isActive(`${item.routerLink}/create`) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Create')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/detail`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Detail')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/update`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Edit')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/agreement`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Agreement')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/minutes`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Minutes')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/decision`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Decision')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/replace`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Replace')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/adjust`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Adjust')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/request`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Request')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/renew`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Renew')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/new`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Create')}`;
              break;
            }
          }
        }
        if (breadcrumb) {
          break;
        }
      }
      if (breadcrumb) {
        BreadcrumbService.setBreadcrumb(breadcrumb);
      }
      if (url) {
        BreadcrumbService.setButtons([], url);
      }
    }
  }

  isActive(url: string, subset?: boolean): boolean {
    const mode = subset ? 'subset' : 'exact';
    return this.router.isActive(url, {paths: mode, queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'});
  }

}
