import {Component, OnInit} from '@angular/core';

import {Col} from '../../../../shared/model';

import {ItemsService} from './items.service';
import {LazyLoadEvent} from 'primeng-lts/api';

@Component({
  selector: 'app-changelogs',
  templateUrl: './changelogs.component.html'
})
export class ChangelogsComponent implements OnInit {

  cols: Array<Col> = [
    {width: 130, fixedWidth: true, field: 'changedDate', header: 'Date', type: '', matchMode: 'contains', format: 'date', visible: true},
    {width: 200, field: 'fieldDesc', header: 'Trường thay đổi', type: 'text', matchMode: 'contains', format: 'text', visible: true},
    {width: 150, field: 'oldValueText', header: 'Giá trị cũ', type: 'text', matchMode: 'contains', format: 'text', visible: true},
    {width: 150, field: 'newValueText', header: 'Giá trị mới', type: 'text', matchMode: 'contains', format: 'text', visible: true},
    {width: 150, field: 'userFullName', header: 'Username', type: 'text', matchMode: 'contains', format: 'text', visible: true}
  ];

  items: Array<any> = new Array<any>();
  totalRecords: number = 0;

  constructor(
    private itemsService: ItemsService
  ) {
    // this.loadLazy = this.loadLazy.bind(this);
  }

  ngOnInit(): void {
    this.itemsService.getChangelogs('').subscribe((response) => {
      this.items = response?.listData;
    });
  }

  // loadLazy(event: LazyLoadEvent): void {
  //   this.itemsService.getChangelogs(JSON.stringify(event)).subscribe((response) => {
  //     this.totalRecords = response?.totalRecords;
  //     this.items = response?.listData;
  //   });
  // }

}
